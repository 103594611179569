import {
  // closeColumn,
  insertColumns,
  // moveColumn,
  // resetColumn
} from '@/store/mutations'
// import { subscriptions } from '@/store/state'
import type { Column } from '@/types'
import { createClient, type Session } from '@supabase/supabase-js'
// import db from './database'
import { ref, computed } from 'vue'
import { logout as logoutFromApi } from '@/plugins/api'
import router from '@/router'
import type { Database } from '@/types/database.types'
import {
  FunctionsFetchError,
  FunctionsHttpError,
  FunctionsRelayError
} from '@supabase/supabase-js'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

export const session = ref<Session | null>(null)
export const isLoggedIn = computed(() => session.value !== null)

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
})

export async function signInWithEmail(email: string) {
  const { data, error } = await supabase.auth.signInWithOtp({
    email,
    options: {
      shouldCreateUser: true,
      emailRedirectTo: 'https://next.afpdeck.app/auth/verify'
    }
  })

  if (error) {
    await handleFunctionsError(error)
    throw new Error('Failed to sign in with email')
  }
  return data
}

export async function verifyEmailToken(email: string, token: string) {
  const {
    data: { session },
    error
  } = await supabase.auth.verifyOtp({
    email,
    token,
    type: 'email'
  })

  if (error) {
    await handleFunctionsError(error)
    throw new Error('Failed to verify email token')
  }
  return session
}

export async function signOut() {
  const { error } = await supabase.auth.signOut({ scope: 'local' })
  if (error) {
    await handleFunctionsError(error)
    throw new Error('Failed to sign out')
  }
}

export function loadColumns() {
  console.log('load columns')
  // get existing columns from supabase
  return supabase
    .from('columns')
    .select('id, order, type, params')
    .order('order', { ascending: true })
    .then(({ data, error }) => {
      if (error) throw error
      if (data) return insertColumns(data as Column[], 0, 0, true) // Replace existing columns
    })
}

// export function loadSubscriptions() {
//   // get existing subscriptions from supabase
//   return supabase
//     .from('subscriptions')
//     .select('column_id')
//     .then(({ data, error }) => {
//       if (error) throw error
//       if (data)
//         subscriptions.value = new Set(
//           data.map((d: { column_id: string }) => d.column_id)
//         )
//     })
// }

// export function syncColumns() {
//   supabase
//     .channel('columns')
//     .on(
//       'postgres_changes',
//       { event: 'INSERT', schema: 'public', table: 'columns' },
//       payload => {
//         const newColumn = {
//           id: payload.new.id,
//           type: payload.new.type,
//           params: payload.new.params,
//           order: payload.new.order
//         } as Column
//         insertColumns([newColumn], payload.new.order)
//       }
//     )
//     .on(
//       'postgres_changes',
//       { event: 'DELETE', schema: 'public', table: 'columns' },
//       payload => {
//         closeColumn(payload.old.id)
//       }
//     )
//     .on(
//       'postgres_changes',
//       { event: 'UPDATE', schema: 'public', table: 'columns' },
//       payload => {
//         db.columns
//           .where('id')
//           .equals(payload.new.id)
//           .modify({ params: payload.new.params })
//         resetColumn(payload.new.id)
//         moveColumn(payload.new.id, payload.new.order)
//       }
//     )
//     .subscribe()
// }

// export function syncSubscriptions() {
//   supabase
//     .channel('subscriptions')
//     .on(
//       'postgres_changes',
//       { event: 'INSERT', schema: 'public', table: 'subscriptions' },
//       payload => {
//         subscriptions.value.add(payload.new.column_id)
//       }
//     )
//     .on(
//       'postgres_changes',
//       { event: 'DELETE', schema: 'public', table: 'subscriptions' },
//       payload => {
//         subscriptions.value.delete(payload.old.column_id)
//       }
//     )
//     .subscribe()
// }

supabase.auth.onAuthStateChange((event, currentSession) => {
  session.value = currentSession
  if (event === 'INITIAL_SESSION') {
    // handle initial session
  } else if (event === 'SIGNED_IN') {
    loadColumns()
    // loadSubscriptions()
    // syncSubscriptions()
  } else if (event === 'SIGNED_OUT') {
    supabase.removeAllChannels()
    logoutFromApi()
    router.push({ name: 'login' })
  } else if (event === 'PASSWORD_RECOVERY') {
    // handle password recovery event
  } else if (event === 'TOKEN_REFRESHED') {
    // handle token refreshed event
  } else if (event === 'USER_UPDATED') {
    // handle user updated event
  }
})

export async function handleFunctionsError(error: unknown) {
  if (error instanceof FunctionsHttpError) {
    const errorMessage = await error.context.json()
    throw new Error(`Function returned an error: ${errorMessage}`)
  } else if (error instanceof FunctionsRelayError) {
    throw new Error(`Relay error: ${error.message}`)
  } else if (error instanceof FunctionsFetchError) {
    throw new Error(`Fetch error: ${error.message}`)
  }
}

export default supabase
