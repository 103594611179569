<script setup lang="ts">
  import { ref, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import apiCore from '@/plugins/api'
  import { session } from '@/plugins/supabase'
  import { useNow, useMemory, usePreferredLanguages } from '@vueuse/core'
  import { preferredLocale } from '@/plugins/i18n'
import { useHead } from '@unhead/vue'

  const i18n = useI18n({ useScope: 'global' })

  const now = useNow({ interval: 1000 })

  const navigatorLanguage = ref(navigator.language)
  const preferredLanguages = usePreferredLanguages()

  const { isSupported: isStorageSupported, memory } = useMemory()

  function size(v: number) {
    const kb = v / 1024 / 1024
    return `${kb.toFixed(2)} MB`
  }

  const serviceWorkerActive = ref(false)

  const isStoragePersistent = ref(false)
  async function isStoragePersisted() {
    return (
      (await navigator.storage) &&
      navigator.storage.persisted &&
      navigator.storage.persisted()
    )
  }
  onMounted(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then(registration => {
        if (registration && registration.active) {
          serviceWorkerActive.value = true
        }
      })
    }

    isStoragePersisted().then(persisted => {
      isStoragePersistent.value = persisted
    })
  })

  useHead({
    title: `Debug | ${import.meta.env.VITE_APP_TITLE}`
  })
</script>

<template>
  <article class="mt-5 p-4">
    <h1 class="mb-8 text-5xl font-bold">Debug</h1>

    <div class="join join-vertical bg-base-100 w-full">
      <div class="collapse-arrow join-item border-base-300 collapse border">
        <input
          type="radio"
          name="my-accordion-4"
          checked
        />
        <div class="collapse-title font-semibold">Authentification</div>
        <div class="collapse-content text-sm">
          <p v-if="apiCore.token">
            API Core | Expire
            {{ $dayjs.fromNow(new Date(apiCore.token.tokenExpires), now) }}.
          </p>
          <p v-else>Vous n'êtes pas authentifié à l'API Core.</p>
          <p v-if="session && session.expires_at">
            Supabase | Expire
            {{ $dayjs.fromNow(new Date(session.expires_at * 1000), now) }}.
          </p>
          <p v-else>Vous n'êtes pas authentifié à Supabase.</p>
        </div>
      </div>
      <div class="collapse-arrow join-item border-base-300 collapse border">
        <input
          type="radio"
          name="my-accordion-4"
        />
        <div class="collapse-title font-semibold">Langues</div>
        <div class="collapse-content text-sm">
          <p>
            Langue active : {{ i18n.locale }} (Fallback :
            {{ i18n.fallbackLocale }})
          </p>
          <p>Langue du navigateur : {{ navigatorLanguage }}</p>
          <p>Langues préférées : {{ preferredLanguages }}</p>
          <p>Locale configurée : {{ preferredLocale }}</p>
        </div>
      </div>
      <div class="collapse-arrow join-item border-base-300 collapse border">
        <input
          type="radio"
          name="my-accordion-4"
        />
        <div class="collapse-title font-semibold">Stockage local</div>
        <div class="collapse-content text-sm">
          <div v-if="isStorageSupported && memory">
            <p>Used: {{ size(memory.usedJSHeapSize) }}</p>
            <p>Allocated : {{ size(memory.totalJSHeapSize) }}</p>
            <p>Limit: {{ size(memory.jsHeapSizeLimit) }}</p>
          </div>
          <p v-else>Your browser does not support performance memory API</p>
          <p>Storage persistant : {{ isStoragePersistent }}</p>
        </div>
      </div>
      <div class="collapse-arrow join-item border-base-300 collapse border">
        <input
          type="radio"
          name="my-accordion-4"
        />
        <div class="collapse-title font-semibold">Service Worker</div>
        <div class="collapse-content text-sm">
          <p v-if="serviceWorkerActive">Le service worker est activé.</p>
          <p v-else>Le service worker n'est pas activé.</p>
        </div>
      </div>
    </div>
  </article>
</template>
