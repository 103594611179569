<script setup lang="ts">
  import ColumnComponent from '@/components/ColumnComponent.vue'
  import { useHead } from '@unhead/vue'
  import { useDeckStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import { useRouter, useRoute } from 'vue-router'

  const deckStore = useDeckStore()
  const { columns } = storeToRefs(deckStore)

  const router = useRouter()
  const route = useRoute()

  useHead({
    title: import.meta.env.VITE_APP_TITLE,
    meta: [
      {
        name: 'site',
        content: import.meta.env.VITE_APP_TITLE
      },
      {
        name: 'description',
        content:
          'Deck is a reader for AFP feeds. It allows you to find and read stories, multimedia articles and photos directly in your browser.'
      }
    ]
  })
</script>

<template>
  <TransitionGroup
    id="columns"
    name="list"
    tag="div"
    class="bg-base-200 flex grow touch-auto snap-x snap-mandatory overflow-x-auto overflow-y-hidden pt-2 select-none print:hidden"
    mode="out-in"
    appear
  >
    <ColumnComponent
      v-for="column in columns"
      :key="`column-${column.id}`"
      :column-id="column.id"
      :column-type="column.type"
      class="snap-center snap-always"
    />
  </TransitionGroup>
  <div
    v-if="route.name !== 'deck'"
    class="bg-neutral absolute top-0 left-0 z-2 h-full w-full cursor-pointer opacity-70 transition-opacity duration-150 select-none hover:opacity-80 print:hidden"
    @click="router.push({ name: 'deck' })"
  />
  <RouterView v-slot="{ Component, route }">
    <template v-if="Component">
      <Transition
        :name="route.meta.transition as string"
        mode="in-out"
        appear
      >
        <component
          :is="Component"
          :key="route.path"
        ></component>
      </Transition>
    </template>
  </RouterView>
</template>

<style lang="scss" scoped>
  .list-leave-to {
    transform: translate(0%, -100%);
  }
  .list-enter-active,
  .list-leave-active,
  .list-move {
    transition: transform 0.5s;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease-in-out;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: transform 0.3s ease-in-out;
  }

  .slide-left-enter-from,
  .slide-left-leave-to,
  .slide-right-enter-from,
  .slide-right-leave-to {
    transform: translateX(-100%);
  }

  @media (max-width: 900px) {
    .slide-right-enter-from,
    .slide-right-leave-to {
      transform: translateX(100%);
    }
  }
</style>
