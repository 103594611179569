import parseDocument, { type Document } from '@/store/parser'
import { v4 as uuidv4 } from 'uuid'
import { columns } from './state'
import {
  agendaGenreIds,
  excludeAgenda,
  excludeGenreIds,
  getColumnById
} from './getters'
import { isLoggedIn, supabase } from '@/plugins/supabase'
import Dexie from 'dexie'
import { type SearchQueryParams, defaultSearchParams } from 'afpnews-api'
import type { Column } from '@/types'
import db from '@/plugins/database'
import { moveArrayItem } from '@/utilities'
import { track } from '@vercel/analytics'
import { max } from 'd3-array'

function generateDefaultColumn(order: number = 0): Column {
  return {
    id: uuidv4(),
    type: 'search',
    lastUpdated: Date.now(),
    params: Object.assign({}, defaultSearchParams as SearchQueryParams, {
      provider: ['afp', 'أ ف ب'],
      size: 10,
      genreid: {
        exclude: ['afpedtype:DocRobot', ...agendaGenreIds()]
      }
    }),
    documentsIds: [],
    order
  }
}

export async function addColumn(payload?: Partial<Column>) {
  const defaultColumn = generateDefaultColumn(
    (max(columns.value, d => d.order) || 0) + 1
  )
  if (payload && payload.params) {
    payload.params = Object.assign(defaultColumn.params, payload.params)
  }
  const newColumn = Object.assign(defaultColumn, payload)
  await db.columns.put(newColumn)
  if (isLoggedIn) {
    await supabase.from('columns').insert({
      id: newColumn.id,
      type: newColumn.type,
      params: newColumn.params,
      order: newColumn.order
    })
  }
}

export async function insertColumns(
  incomingColumns: Column[],
  start = 0,
  deleteCount = 0,
  deleteOthers = false
) {
  // Supprimer les colonnes si besoin
  if (deleteCount > 0) {
    await db.columns
      .where('order')
      .between(start, start + deleteCount)
      .delete()
  }

  const currentColumns = await db.columns.toArray()

  // Identifier les colonnes à fusionner
  const currentColumnsToMerge = currentColumns
    .filter(column => incomingColumns.find(c => c.id === column.id))
    .map(column => {
      const defaultColumn = generateDefaultColumn()
      const incomingColumn = incomingColumns.find(c => c.id === column.id)
      return {
        id: column.id,
        type: column.type,
        lastUpdated: column.lastUpdated,
        documentsIds: column.documentsIds,
        order: incomingColumn?.order || column.order,
        params: Object.assign(
          defaultColumn.params,
          column.params,
          incomingColumn?.params || {}
        )
      }
    })

  // Supprimer les autres colonnes si besoin
  if (deleteOthers) {
    const currentColumnsToDelete = currentColumns.filter(
      column => !incomingColumns.find(c => c.id === column.id)
    )
    await db.columns.bulkDelete(currentColumnsToDelete.map(column => column.id))
  }

  // Insérer de nouvelles colonnes
  const newColumns = incomingColumns
    .filter(column => !currentColumns.find(c => c.id === column.id))
    .map((column, i) => {
      const defaultColumn = generateDefaultColumn()
      if (column && column.params) {
        column.params = Object.assign(defaultColumn.params, column.params)
      }
      return {
        id: column.id,
        type: column.type || defaultColumn.type,
        lastUpdated: column.lastUpdated || defaultColumn.lastUpdated,
        documentsIds: defaultColumn.documentsIds,
        order: column.order || start + i,
        params: Object.assign(defaultColumn.params, column.params)
      }
    })

  await db.columns.bulkPut([...currentColumnsToMerge, ...newColumns])
}

export async function moveColumn(
  columnId: string,
  dir: 'left' | 'right' | number
) {
  // move column in local db
  const sortingArray = columns.value.map(d => d.id)
  const indexCol = sortingArray.findIndex(d => d === columnId)
  const newArray = moveArrayItem(sortingArray, indexCol, dir)

  await db.transaction('rw', db.columns, () => {
    return Promise.all(
      newArray.map((id, index) => db.columns.update(id, { order: index }))
    )
  })

  if (isLoggedIn) {
    await supabase.from('columns').upsert(
      columns.value.map(
        (column) => ({
          id: column.id,
          order: newArray.findIndex(d => column.id == d),
          params: column.params,
          type: column.type
        }),
        { ignoreDuplicates: true, onConflict: 'id' }
      )
    )
  }
}

export async function closeColumn(columnId: string) {
  await db.columns.delete(columnId)
  if (isLoggedIn) {
    await supabase.from('columns').delete().eq('id', columnId)
  }
}

export async function resetColumn(columnId: string) {
  await db.columns.where('id').equals(columnId).modify({ documentsIds: [] })
}

export async function toggleAgenda(columnId: string) {
  let newExcludeGenreIds = []
  if (excludeAgenda(columnId)) {
    newExcludeGenreIds = excludeGenreIds(columnId).filter(
      d => !agendaGenreIds().find(e => e === d)
    )
  } else {
    newExcludeGenreIds = [...excludeGenreIds(columnId), ...agendaGenreIds()]
  }
  const params = getColumnById(columnId).params
  await updateColumnParams(columnId, {
    ...params,
    genreid: {
      exclude: newExcludeGenreIds as [string | number]
    }
  })
  await resetColumn(columnId)
}

export async function updateColumnParams(
  columnId: string,
  params: SearchQueryParams
) {
  track('update_column_params', {
    langs: params.langs?.join('|') || '',
    query: params.query || ''
  })
  await db.columns
    .where('id')
    .equals(columnId)
    .modify({ params: Dexie.deepClone(params) })

  if (isLoggedIn) {
    await supabase.from('columns').update({ params }).eq('id', columnId)
  }
}

export function updateColumnLastUpdated(columnId: string) {
  return db.columns
    .where('id')
    .equals(columnId)
    .modify({ lastUpdated: Date.now() })
}

export function parseDocuments(documentsToParse: unknown[]) {
  return documentsToParse
    .map(parseDocument)
    .filter((doc): doc is Document => !!doc)
}

export async function addDocuments(documentsToAdd: unknown[]) {
  if (Array.isArray(documentsToAdd)) {
    const docs = parseDocuments(documentsToAdd)
    await db.documents.bulkPut(Dexie.deepClone(docs))
    return docs
  }
  return []
}

export function prependDocumentsIdsToCol(
  columnId: string,
  documentsIds: string[]
) {
  // Si le nombre de documents à ajouter est égal au nombre de documents demandé, alors il y a possiblement des documents manquants, donc on rafraichit toute la colonne
  const column = getColumnById(columnId)
  const existingDocumentsIds =
    documentsIds.length == (column.params.size || 10)
      ? []
      : getColumnById(columnId).documentsIds

  return db.columns
    .where('id')
    .equals(columnId)
    .modify({
      documentsIds: [...documentsIds, ...existingDocumentsIds]
    })
}

export function appendDocumentsIdsToCol(
  columnId: string,
  documentsIds: string[]
) {
  const existingDocumentsIds = getColumnById(columnId).documentsIds
  return db.columns
    .where('id')
    .equals(columnId)
    .modify({
      documentsIds: [...existingDocumentsIds, ...documentsIds]
    })
}

/**
 * Nettoie la base de données en supprimant les documents qui ne sont plus affichés dans les colonnes.
 * Cette fonction :
 * 1. Récupère tous les IDs de documents actuellement affichés dans les colonnes
 * 2. Compare avec tous les documents stockés dans la base
 * 3. Supprime les documents qui ne sont plus référencés par aucune colonne
 */
export async function cleanColumns() {
  const allColumns = await db.columns.toArray()
  const allDisplayedDocumentsIds = allColumns.flatMap(
    column => column.documentsIds
  )
  await db.transaction('rw', db.documents, async () => {
    const allStoredDocumentsIds = await db.documents
      .toCollection()
      .primaryKeys()
    const documentsIdsToDelete = allStoredDocumentsIds.filter(
      id => !allDisplayedDocumentsIds.includes(id)
    )
    await db.documents.bulkDelete(documentsIdsToDelete)
    console.debug(
      `${documentsIdsToDelete.length} document(s) supprimé(s) de la base de données locale`
    )
  })
}

export async function clearStorage() {
  await db.documents.clear()
  await db.columns.clear()
}
