<script setup lang="ts">
  import useInstallApp from '@/composables/useInstallApp'
  import { useI18n } from 'vue-i18n'
  import info from '@/../package.json'
  import { ref } from 'vue'
  import { useHead } from '@unhead/vue'

  const i18n = useI18n({ useScope: 'global' })
  const { t } = i18n

  const version = ref(info.version)

  const { promptStorage } = useInstallApp()

  useHead({
    title: `About | ${import.meta.env.VITE_APP_TITLE}`
  })
</script>

<template>
  <article class="hero bg-base-200 grow">
    <div class="hero-content text-center">
      <div class="max-w-md">
        <h1 class="text-5xl font-bold">{{ t('about.title') }}</h1>
        <p class="py-6">
          {{ t('about.description') }}
        </p>
        <fieldset
          v-if="promptStorage"
          class="fieldset"
        >
          <legend class="fieldset-legend">{{ t('about.mobile') }}</legend>
          <button
            :aria-label="t('install-app')"
            class="btn btn-primary"
            @click.prevent="promptStorage"
          >
            {{ t('about.install-mobile-app') }}
          </button>
        </fieldset>
        <fieldset class="fieldset">
          <legend class="fieldset-legend">{{ t('about.desktop') }}</legend>
          <a
            class="btn btn-primary"
            href="/"
            >{{ t('about.desktop-app') }}</a
          >
        </fieldset>
        <fieldset class="fieldset">
          <legend class="fieldset-legend">{{ t('about.contact') }}</legend>
          <a
            class="btn btn-outline btn-primary"
            :href="`mailto:jules.bonnard@afp.com?subject=Deck&body=Deck version : ${version}`"
            >{{ t('about.contact-link') }}</a
          >
        </fieldset>
      </div>
    </div>
  </article>
  <footer
    class="footer sm:footer-horizontal footer-center bg-base-300 text-base-content p-4"
  >
    <aside>
      <p>
        <RouterLink
          :to="{ name: 'debug' }"
          class="has-text-primary"
        >
          {{ t('about.version') }} {{ version }}
        </RouterLink>
      </p>
    </aside>
  </footer>
</template>
