<script lang="ts" setup>
  import { ref, toRefs, watchEffect, type Ref } from 'vue'

  const props = defineProps({
    explanation: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  })

  const model = defineModel({ required: true, type: String })

  const codeInputs = ref<(HTMLInputElement | null)[]>([])

  const code: Ref<string[]> = ref(new Array(6))

  watchEffect(() => {
    if (code.value.every(d => d !== null)) {
      model.value = code.value.join('')
    }
  })

  const { explanation } = toRefs(props)

  const codeLength = 6

  const onInput = (e: Event, index: number) => {
    const input = e.target as HTMLInputElement
    const value = input.value
    code.value[index] = value

    if (value.length === 1 && index < codeLength - 1) {
      codeInputs.value[index + 1]?.focus()
    }
  }

  const onPaste = (e: ClipboardEvent) => {
    const paste = e.clipboardData?.getData('text') ?? ''
    if (/^\d{6}$/.test(paste)) {
      e.preventDefault()
      paste.split('').forEach((char, i) => {
        const input = codeInputs.value[i]
        if (input) {
          input.value = char
          code.value[i] = char
        }
      })
      codeInputs.value[codeLength - 1]?.focus()
    }
  }

  const onKeydown = (e: KeyboardEvent, index: number) => {
    const input = e.target as HTMLInputElement
    if (e.key === 'Backspace' && !input.value && index > 0) {
      codeInputs.value[index - 1]?.focus()
    }
  }
</script>

<template>
  <form class="mx-auto max-w-sm">
    <p
      v-if="explanation"
      id="helper-text-explanation"
      class="text-base-content/60 mb-2 text-xs"
    >
      {{ explanation }}
    </p>
    <div
      @paste="onPaste"
      class="mb-2 flex space-x-2 rtl:space-x-reverse"
    >
      <div
        v-for="(_, i) in codeLength"
        :key="i"
      >
        <label
          :for="`code-${i}`"
          class="sr-only"
          >Code {{ 1 }}</label
        >
        <input
          type="text"
          maxlength="1"
          @input="e => onInput(e, i)"
          @keydown="e => onKeydown(e, i)"
          ref="codeInputs"
          data-focus-input-init
          :data-focus-input-next="`code-${i + 1}`"
          :id="`code-${i}`"
          class="border-base-300 bg-white dark:bg-black block size-9 rounded-lg border py-3 text-center text-sm font-extrabold"
          autocomplete="false"
          required
        />
      </div>
    </div>
  </form>
</template>

<!-- class="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 border-gray-300 bg-white  text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
          :disabled="disabled" -->
